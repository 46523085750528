// パーツの読み込み
@import '../common/_variable.scss'; // 変数設定の読み込み

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// ヘッダーSP（たまごカート用）
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  .header {
    // リセットcss
    html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}body{line-height:1;}ol,ul{list-style:none;}blockquote,q{quotes:none;}blockquote::before,blockquote::after,q::before,q::after{content:'';content:none;}table{border-collapse:collapse;border-spacing:0;}input,select,button,textarea{margin:0;padding:0;background:transparent;border:none;border-radius:0;outline:none;appearance:none;}select::-ms-expand{display:none;}*,*::before,*::after{box-sizing:border-box;}
    // ベース調整
    line-height: 1.6;
    letter-spacing: 0.05em;
    a {color: $blk;text-decoration: none;overflow-wrap: break-word;transition: 0.3s ease-out;&:visited{color:$blk;}&:hover{color:$blk;}&:active {color:$blk;}&[href*="tel:"]{text-decoration:none;pointer-events:none;cursor:default;}}
    img {display:block;max-width:100%;height:auto;vertical-align:bottom;&:not(.no-fit){width:100%;}}
    .list__item{margin-left:1em;text-indent:-1em;&:not(:nth-last-of-type(1)){margin-bottom:4px;}&::before {content:'・';}}

    .header-info {
      width: 100%;
      max-width: 1280px;
      margin-right: auto;
      margin-left: auto;
      padding: 12px 16px;
      font-size: 16px;
      text-align: left;
      @include break(xs) {
        padding: 12px;
      }
      &__wrap {
        @include flex(space-between,center);
        margin: 0 auto;
      }
    }
    .header-primary {
      width: calc(100% - 152px);
      @include break(xs) {
        width: calc(100% - 132px);
      }
    }
    .header-secondary {
      width: 136px;
      @include break(xs) {
        width: 120px;
      }
    }
    .header-logo {
      max-width: 160px;
      @include break(xs) {
        max-width: 140px;
      }
    }
    .header-menu {
      @include flex(right);
      .header-menu-registry,
      .header-menu-cart {
        width: 64px;
        height: 64px;
        @include break(xs) {
          width: 56px;
          height: 56px;
        }
        a {
          @include flex(center,center,column);
          gap: 6px;
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 2px;
          color: $wht;
          font-size: 10px;
          @include break(xs) {
            gap: 4px;
          }
          &::after {
            @include pos($r:4px,$b:4px);
            content: '';
            width: 6px;
            height: 6px;
            background: linear-gradient( to bottom right, $tra 50%, $wht 50%);
            @include break(xs) {
              right: 3px;
              bottom: 3px;
              width: 4px;
              height: 4px;
            }
          }
        }
      }
      .header-menu-registry {
        margin-right: 8px;
        a {
          background: $grn;
          .ico-registry {
            width: 20px;
            height: 20px;
            fill: $wht;
          }
        }
      }
      .header-menu-cart {
        a {
          background: $red;
          .ico-cart {
            width: 20px;
            height: 20px;
            fill: $wht;
          }
        }
      }
    }
  }

  
  .tbl {
    // リセットcss
    html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}body{line-height:1;}ol,ul{list-style:none;}blockquote,q{quotes:none;}blockquote::before,blockquote::after,q::before,q::after{content:'';content:none;}table{border-collapse:collapse;border-spacing:0;}input,select,button,textarea{margin:0;padding:0;background:transparent;border:none;border-radius:0;outline:none;appearance:none;}select::-ms-expand{display:none;}*,*::before,*::after{box-sizing:border-box;}
    table {
      box-sizing: border-box;
      width: 100%;
      border-top: solid 1px $gry;
      border-left: solid 1px $gry;
      font-size: 16px;
      tbody {
        display: block;
        tr {
          display: block;
          th,
          td {
            display: block;
            padding: 8px 12px;
            border-right: solid 1px $gry;
            border-bottom: solid 1px $gry;
          }
          th {
            width: 100%;
            background: $l-gry;
            text-align: left;
          }
          td {
            width: 100%;
          }
        }
      }//tbody
    }//table
  }//.tbl

//------------------------------//
// 送料無料表示（デザイン設定 > PC or SP > レイアウトプルダウン > レコメンド）
//------------------------------//
.free-shipping-bnr {
  @include flex(center, center);
  margin: 16px auto;
  width: 100%;
  max-width: 280px;
  padding: 16px;
  border: solid 2px #c51205;
  background: #fdd467;
  color: #c51205;
  font-size: 1.4rem;
  font-weight: bold;
}
